<template>
  <div class="login-wrap">
    <div class="login-form">
      <div class="title-form">
        <h2>登 录</h2>
      </div>
      <div id="wxloader"></div>
    </div>
  </div>
</template>
<script>
import {wechatAppid, loginUrl} from "@/utils/public"
export default {
  data() {
    return {
      activeName: "verification",
    };
  },
  components: {},
  methods: {},
  mounted() {
    console.log("weixin.vue is called.");
    var obj = new WxLogin({
      id: "wxloader",
      appid: wechatAppid,
      scope: "snsapi_login",
      redirect_uri: encodeURIComponent(loginUrl),
      state: "",
      style: "black",
    });
  },
};
</script>
<style lang="scss" scoped>
.login-wrap {
  width: 100%;
  height: 100%;
  background-size: cover;
  .login-form {
    width: 600px;
    height: 600px;
    background: rgba(255, 255, 255, 1);
    border-radius: 32px;
    box-sizing: border-box;
    padding: 45px 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 50px;
    .title-form {
      text-align: center;
      color: #000;
      margin-bottom: 45px;
    }
    .el-tabs {
      width: 400px;
      margin: auto;
    }
    ::v-deep .el-tabs__nav {
      transform: translateX(114px) !important;
    }
    ::v-deep .el-tabs__item {
      font-size: 16px;
    }
    ::v-deep .el-tabs__item.is-active {
      //   color: linear-gradient(
      //     90deg,
      //     rgba(74, 99, 240, 0.93) 0%,
      //     rgba(236, 179, 242, 1) 100%
      //   );
      color: rgba(74, 99, 240, 0.93);
    }
    ::v-deep .el-tabs__active-bar {
      background-color: #4a63f0ed;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      background-color: transparent;
    }
  }
}
</style>